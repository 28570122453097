<template>
  <div class="row px-md-4">
    <div class="col-12 col-sm-12">
      <h2 class="main-title">Auditor</h2>
    </div>

    <div class="col-12 col-sm-12">
      <div class="card">
        <div class="row" v-if="loading == true">
          <div class="col-12 col-sm-12">
            <grid-loader
              class="expertel-loader"
              color="#ff502d"
              :loading="loading"
              :size="20"
              sizeUnit="px"
            ></grid-loader>
          </div>
        </div>
        <div v-if="loading == false" class="overflow-auto">
          <div class="message-auditor">
            <p class="mb-0">
              Sometimes the carrier may not provision features correctly when
              starting. It appears you have been mistakenly billed for charges
              that should be included in your plan. Please report the below
              findings to your carrier and request a credit for the incorrect
              billed items, also have them adding the missing features to
              prevent this happening again.
            </p>
          </div>
          <table class="table table-responsivex table-2 table-auditor">
            <thead>
              <tr>
                <th>User Name</th>
                <th>Phone Number</th>
                <th>Charges this month</th>
                <th>How many months this has ocurred</th>
                <th>Missing Feature</th>
                <th>Amount the carrier owes you</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, key) in auditor_data" :key="key">
                <td>
                  {{ data.user_name }}
                </td>
                <td>
                  {{ data.phone_number | phoneNumber }}
                </td>
                <td>${{ data.amount }}</td>
                <td>
                  {{ data.previous.number }}
                </td>
                <td>
                  {{ data.feature_name }}
                </td>
                <td class="color-success font-circular font-bold">
                  ${{ data.previous.amount }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["login_data"],
  data() {
    return {
      loading: true,
      auditor_data: []
    };
  },
  async created() {
    if (this.login_data !== undefined || this.login_data.success === false) {
      if (!this.login_data.success) {
        this.$router.push("/");
      } else {
        this.getData();
      }
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    async getData() {
      const self = this;
      this.loading = true;
      await this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/client/me/plan_validator`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(async function(response) {
          self.auditor_data = response.data;
          self.loading = false;
          // self.auditor_data = response.data.sort(
          //   (a, b) => new Date(b.report_date) - new Date(a.report_date)
          // );
        })
        .catch(error => this.makeToast("ERROR", error, "danger"));
    },
    makeToast(message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: "Error",
        autoHideDelay: 5000,
        appendToast: true,
        variant: variant
      });
    }
  }
};
</script>

<style lang="scss">
.message-auditor {
  margin-bottom: 1.5rem;
  padding: 0.6rem 1.2rem;
  border-radius: 8px;
  background-color: #efe8e8;
  border: 2px solid #eadfe1;
}

.table-2 thead th {
  text-align: center;
}
</style>
